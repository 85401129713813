[data-theme="dark"] div.btns {
  filter: brightness(0.7);
}
[data-theme="dark"] div.btns a {
  background: 0 0;
}
[data-theme="dark"] .checkbox {
  filter: brightness(0.7);
}
div.btns {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: visible;
  line-height: 1.8;
}
div.btns b {
  font-size: 0.875rem;
}
div.btns.wide > a {
  padding-left: 32px;
  padding-right: 32px;
}
div.btns.fill > a {
  flex-grow: 1;
  width: auto;
}
div.btns.around {
  justify-content: space-around;
}
div.btns.center {
  justify-content: center;
}
div.btns.grid2 > a {
  width: calc(100% / 2 - 16px);
}
div.btns.grid3 > a {
  width: calc(100% / 3 - 16px);
}
div.btns.grid4 > a {
  width: calc(100% / 4 - 16px);
}
div.btns.grid5 > a {
  width: calc(100% / 5 - 16px);
}
div.btns a {
  transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  margin: 8px;
  margin-top: calc(1.25 * 16px + 32px);
  min-width: 120px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  text-align: center;
  background: #f6f6f6;
  border-radius: 4px;
}
div.btns a > i {
  background: #2196f3 !important;
}
div.btns a > i:first-child {
  color: #fff;
  background: #2196f3;
}
div.btns a b {
  font-weight: bold;
  line-height: 1.3;
}
div.btns a img {
  margin: 0.4em auto;
}
div.btns a:not([href]) {
  cursor: default;
  color: inherit;
}
div.btns a[href]:hover {
  background: rgba(255,87,34,0.15);
}
div.btns a[href]:hover > i:first-child {
  background: #ff5722;
}
div.btns,
div.btns p,
div.btns a {
  font-size: 0.8125rem;
  color: #555;
}
@media screen and (max-width: 1024px) {
  div.btns.grid2 > a {
    width: calc(100% / 2 - 16px);
  }
}
@media screen and (max-width: 768px) {
  div.btns.grid2 > a {
    width: calc(100% / 2 - 16px);
  }
}
@media screen and (max-width: 500px) {
  div.btns.grid2 > a {
    width: calc(100% / 1 - 16px);
  }
}
@media screen and (max-width: 1024px) {
  div.btns.grid3 > a {
    width: calc(100% / 3 - 16px);
  }
}
@media screen and (max-width: 768px) {
  div.btns.grid3 > a {
    width: calc(100% / 3 - 16px);
  }
}
@media screen and (max-width: 500px) {
  div.btns.grid3 > a {
    width: calc(100% / 1 - 16px);
  }
}
@media screen and (max-width: 1024px) {
  div.btns.grid4 > a {
    width: calc(100% / 3 - 16px);
  }
}
@media screen and (max-width: 768px) {
  div.btns.grid4 > a {
    width: calc(100% / 3 - 16px);
  }
}
@media screen and (max-width: 500px) {
  div.btns.grid4 > a {
    width: calc(100% / 2 - 16px);
  }
}
@media screen and (max-width: 1024px) {
  div.btns.grid5 > a {
    width: calc(100% / 4 - 16px);
  }
}
@media screen and (max-width: 768px) {
  div.btns.grid5 > a {
    width: calc(100% / 3 - 16px);
  }
}
@media screen and (max-width: 500px) {
  div.btns.grid5 > a {
    width: calc(100% / 2 - 16px);
  }
}
div.btns a > img:first-child,
div.btns a > i:first-child {
  transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  height: 64px;
  width: 64px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
  margin: 16px 8px 4px 8px;
  margin-top: calc(-1.25 * 16px - 32px);
  border: 2px solid #fff;
  background: #fff;
  line-height: 60px;
  font-size: 28px;
}
div.btns a > img:first-child.auto,
div.btns a > i:first-child.auto {
  width: auto;
}
div.btns a p,
div.btns a b {
  margin: 0.25em;
  font-weight: normal;
  line-height: 1.25;
  word-wrap: break-word;
}
div.btns a[href]:hover,
div.btns a[href]:hover b {
  color: #ff5722;
}
div.btns a[href]:hover > img:first-child,
div.btns a[href]:hover > i:first-child {
  transform: scale(1.1) translateY(-8px);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
}
div.btns.circle a > img:first-child,
div.btns.circle a > i:first-child {
  border-radius: 32px;
}
div.btns.rounded a > img:first-child,
div.btns.rounded a > i:first-child {
  border-radius: 16px;
}
:root {
  --bubble-text-color: #e9a218;
  --bubble-text-hover-color: #2c7fe7;
  --bubble-text-shadow: rgba(35,35,35,0.5);
}
[data-theme="dark"] {
  --bubble-text-color: #f2b94b;
  --bubble-text-hover-color: #2c7fe7;
  --bubble-text-shadow: rgba(35,35,35,0.5);
}
.bubble-content {
  display: inline-block;
  color: var(--bubble-text-color);
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-shadow: var(--bubble-text-shadow);
}
.bubble-content:hover {
  transition: all 0.2s ease-in-out;
  color: var(--bubble-text-hover-color);
}
.bubble-content:hover + .bubble-notation .bubble-item {
  transform: translate(-40px, 10px) rotateX(0deg);
  transition: all 0.5s ease-in-out;
  opacity: 1;
}
.bubble-notation {
  display: inline-block;
}
.bubble-item {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  color: #fff;
  z-index: 99;
  display: flex;
  position: absolute;
  transform: translate(-40px, 10px) rotateX(90deg);
  width: auto;
  height: auto;
  max-width: 400px;
  overflow: hidden;
  padding: 20px 10px 10px 10px;
  clip-path: polygon(5px 10px, 20px 10px, 30px 0, 40px 10px, calc(100% - 5px) 10px, 100% 15px, 100% calc(100% - 5px), calc(100% - 5px) 100%, 5px 100%, 0 calc(100% - 5px), 0 15px, 5px 10px);
}
.carousel {
  touch-action: none;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: transparent;
  height: fit-content;
  min-height: 600px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.drag-container,
.spin-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-10deg);
  transform: rotateX(-10deg);
}
.drag-container img,
.drag-container video {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  line-height: 200px;
  font-size: 50px;
  text-align: center;
  -webkit-box-shadow: 0 0 8px #fff;
  box-shadow: 0 0 8px #fff;
  -webkit-box-reflect: below 10px linear-gradient(transparent, transparent, rgba(0,0,0,0.333));
}
.drag-container .img-alt.is-center {
  display: none;
}
.drag-container br {
  display: none;
}
.drag-container p {
  font-family: Serif;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateX(90deg);
  transform: translate(-50%, -50%) rotateX(90deg);
  color: #fff;
}
.drag-container img:hover,
.drag-container video:hover {
  -webkit-box-shadow: 0 0 15px rgba(255,255,255,0.867);
  box-shadow: 0 0 15px rgba(255,255,255,0.867);
  -webkit-box-reflect: below 10px linear-gradient(transparent, transparent, rgba(0,0,0,0.467));
}
.carousel-ground {
  width: 900px;
  height: 900px;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateX(90deg);
  transform: translate(-50%, -50%) rotateX(90deg);
  background: -webkit-radial-gradient(center center, farthest-side, rgba(153,153,153,0.2), transparent);
}
img.medium-zoom-image.entered.loaded.medium-zoom-image--opened {
  width: auto !important;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes spinRevert {
  from {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@-moz-keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@-o-keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@-moz-keyframes spinRevert {
  from {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes spinRevert {
  from {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@-o-keyframes spinRevert {
  from {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@keyframes spinRevert {
  from {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
.checkbox {
  display: flex;
  align-items: center;
}
.checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  height: 16px;
  width: 16px;
  transition: all 0.15s ease-out 0s;
  cursor: pointer;
  display: inline-block;
  outline: none;
  border-radius: 2px;
  flex-shrink: 0;
  margin-right: 8px;
  border: 2px solid #2196f3;
  pointer-events: none;
}
.checkbox input[type="checkbox"]:before {
  left: 1px;
  top: 5px;
  width: 0;
  height: 2px;
  transition: all 0.2s ease-in;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.checkbox input[type="checkbox"]:after {
  right: 7px;
  bottom: 3px;
  width: 2px;
  height: 0;
  transition: all 0.2s ease-out;
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  transition-delay: 0.25s;
}
.checkbox input[type="checkbox"]:checked {
  background: #2196f3;
}
.checkbox input[type="checkbox"]:checked:before {
  left: 0;
  top: 7px;
  width: 6px;
  height: 2px;
}
.checkbox input[type="checkbox"]:checked:after {
  right: 3px;
  bottom: 1px;
  width: 2px;
  height: 10px;
}
.checkbox.minus input[type="checkbox"]:before {
  transform: rotate(0);
  left: 1px;
  top: 5px;
  width: 0;
  height: 2px;
}
.checkbox.minus input[type="checkbox"]:after {
  transform: rotate(0);
  left: 1px;
  top: 5px;
  width: 0;
  height: 2px;
}
.checkbox.minus input[type="checkbox"]:checked:before {
  left: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.checkbox.minus input[type="checkbox"]:checked:after {
  left: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.checkbox.plus input[type="checkbox"]:before {
  transform: rotate(0);
  left: 1px;
  top: 5px;
  width: 0;
  height: 2px;
}
.checkbox.plus input[type="checkbox"]:after {
  transform: rotate(0);
  left: 5px;
  top: 1px;
  width: 2px;
  height: 0;
}
.checkbox.plus input[type="checkbox"]:checked:before {
  left: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.checkbox.plus input[type="checkbox"]:checked:after {
  left: 5px;
  top: 1px;
  width: 2px;
  height: 10px;
}
.checkbox.times input[type="checkbox"]:before {
  transform: rotate(45deg);
  left: 3px;
  top: 1px;
  width: 0;
  height: 2px;
}
.checkbox.times input[type="checkbox"]:after {
  transform: rotate(135deg);
  right: 3px;
  top: 1px;
  width: 0;
  height: 2px;
}
.checkbox.times input[type="checkbox"]:checked:before {
  left: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.checkbox.times input[type="checkbox"]:checked:after {
  right: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.checkbox input[type="radio"] {
  border-radius: 50%;
}
.checkbox input[type="radio"]:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 2px;
  transform: scale(0);
  transition: all 0.25s ease-out;
}
.checkbox input[type="radio"]:checked:before {
  transform: scale(1);
  background: var(--text-bg-hover);
}
.checkbox.red input {
  border-color: #fe5f58;
}
.checkbox.red input[type="checkbox"]:checked {
  background: #fe5f58;
}
.checkbox.red input[type="radio"]:checked:before {
  background: #fe5f58;
}
.checkbox.green input {
  border-color: #3dc550;
}
.checkbox.green input[type="checkbox"]:checked {
  background: #3dc550;
}
.checkbox.green input[type="radio"]:checked:before {
  background: #3dc550;
}
.checkbox.yellow input {
  border-color: #ffbd2b;
}
.checkbox.yellow input[type="checkbox"]:checked {
  background: #ffbd2b;
}
.checkbox.yellow input[type="radio"]:checked:before {
  background: #ffbd2b;
}
.checkbox.cyan input {
  border-color: #1bcdfc;
}
.checkbox.cyan input[type="checkbox"]:checked {
  background: #1bcdfc;
}
.checkbox.cyan input[type="radio"]:checked:before {
  background: #1bcdfc;
}
.checkbox.blue input {
  border-color: #2196f3;
}
.checkbox.blue input[type="checkbox"]:checked {
  background: #2196f3;
}
.checkbox.blue input[type="radio"]:checked:before {
  background: #2196f3;
}
.checkbox p {
  display: inline-block;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
}
.checkbox input[type="checkbox"]:before,
.checkbox input[type="checkbox"]:after {
  position: absolute;
  content: "";
  background: #fff;
}
[data-theme="dark"] .checkbox {
  filter: brightness(0.7);
}
details.folding-tag {
  display: block;
  padding: 16px;
  margin: 1em 0;
  border-radius: 4px;
  background: #fff;
  font-size: var(--global-font-size);
  transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  border: 1px solid #f6f6f6;
}
details.folding-tag summary {
  cursor: pointer;
  padding: 16px;
  margin: -16px;
  border-radius: 4px;
  color: rgba(68,68,68,0.7);
  font-size: 0.875rem !important;
  font-weight: bold;
  position: relative;
  line-height: normal;
}
details.folding-tag summary > p,
details.folding-tag summary > h1,
details.folding-tag summary > h2,
details.folding-tag summary > h3,
details.folding-tag summary > h4,
details.folding-tag summary > h5,
details.folding-tag summary > h6 {
  display: inline;
  border-bottom: none !important;
}
details.folding-tag summary:hover {
  color: #444;
}
details.folding-tag summary:hover:after {
  position: absolute;
  content: '+';
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
details.folding-tag >summary {
  background: #f6f6f6;
}
details.folding-tag[purple] {
  border-color: #fae7fd;
}
details.folding-tag[purple] >summary {
  background: #fae7fd;
}
details.folding-tag[blue] {
  border-color: #e8f4fd;
}
details.folding-tag[blue] >summary {
  background: #e8f4fd;
}
details.folding-tag[cyan] {
  border-color: #e8fafe;
}
details.folding-tag[cyan] >summary {
  background: #e8fafe;
}
details.folding-tag[green] {
  border-color: #ebf9ed;
}
details.folding-tag[green] >summary {
  background: #ebf9ed;
}
details.folding-tag[yellow] {
  border-color: #fff8e9;
}
details.folding-tag[yellow] >summary {
  background: #fff8e9;
}
details.folding-tag[orange] {
  border-color: #fdf1e7;
}
details.folding-tag[orange] >summary {
  background: #fdf1e7;
}
details.folding-tag[red] {
  border-color: #feefee;
}
details.folding-tag[red] >summary {
  background: #feefee;
}
details.folding-tag[open] {
  border-color: rgba(68,68,68,0.2);
}
details.folding-tag[open] >summary {
  border-bottom: 1px solid rgba(68,68,68,0.2);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
details.folding-tag[open][purple] {
  border-color: rgba(208,23,238,0.3);
}
details.folding-tag[open][purple] >summary {
  border-bottom-color: rgba(208,23,238,0.3);
}
details.folding-tag[open][blue] {
  border-color: rgba(33,150,243,0.3);
}
details.folding-tag[open][blue] >summary {
  border-bottom-color: rgba(33,150,243,0.3);
}
details.folding-tag[open][cyan] {
  border-color: rgba(27,205,252,0.3);
}
details.folding-tag[open][cyan] >summary {
  border-bottom-color: rgba(27,205,252,0.3);
}
details.folding-tag[open][green] {
  border-color: rgba(61,197,80,0.3);
}
details.folding-tag[open][green] >summary {
  border-bottom-color: rgba(61,197,80,0.3);
}
details.folding-tag[open][yellow] {
  border-color: rgba(255,189,43,0.3);
}
details.folding-tag[open][yellow] >summary {
  border-bottom-color: rgba(255,189,43,0.3);
}
details.folding-tag[open][orange] {
  border-color: rgba(236,118,22,0.3);
}
details.folding-tag[open][orange] >summary {
  border-bottom-color: rgba(236,118,22,0.3);
}
details.folding-tag[open][red] {
  border-color: rgba(254,95,88,0.3);
}
details.folding-tag[open][red] >summary {
  border-bottom-color: rgba(254,95,88,0.3);
}
details.folding-tag[open] >summary {
  color: #444;
  margin-bottom: 0;
}
details.folding-tag[open] >summary:hover:after {
  content: '-';
}
details.folding-tag[open] >div.content {
  padding: 16px;
  margin: -16px;
  margin-top: 0;
}
details.folding-tag[open] >div.content p>a:hover {
  text-decoration: underline;
}
details.folding-tag[open] >div.content > p:first-child,
details.folding-tag[open] >div.content > .tabs:first-child,
details.folding-tag[open] >div.content > ul:first-child,
details.folding-tag[open] >div.content > ol:first-child,
details.folding-tag[open] >div.content > .highlight:first-child,
details.folding-tag[open] >div.content > .note:first-child,
details.folding-tag[open] >div.content > details:first-child {
  margin-top: 0;
}
details.folding-tag[open] >div.content > p:last-child,
details.folding-tag[open] >div.content > .tabs:last-child,
details.folding-tag[open] >div.content > ul:last-child,
details.folding-tag[open] >div.content > ol:last-child,
details.folding-tag[open] >div.content > .highlight:last-child,
details.folding-tag[open] >div.content > .note:last-child,
details.folding-tag[open] >div.content > details:last-child {
  margin-bottom: 0;
}
[data-theme="dark"] details[open] > div.content {
  padding: 16px;
  margin: -16px;
  margin-top: 0;
  background: #2c2d2d;
  color: rgba(255,255,255,0.6);
}
[data-theme="dark"] details > summary {
  filter: brightness(0.7);
}
a.ghcard {
  display: inline-block;
  line-height: 0;
}
.md .ghcard-group {
  column-count: 2;
  column-gap: 0;
  margin: 0 -8px;
}
.md .ghcard-group .ghcard {
  margin: 8px;
}
.md .img {
  object-fit: contain;
}
img.inline {
  display: inline !important;
  vertical-align: middle;
  transform: translateY(-4px);
}
p .img-alt {
  display: inline-block;
  width: 100%;
}
s,
del {
  color: #8e8e8e;
  text-decoration-color: #8e8e8e;
}
u {
  color: #444;
  text-decoration: none;
  border-bottom: 1px solid #fe5f58;
}
emp {
  color: #444;
  border-bottom: 4px dotted #fe5f58;
}
wavy {
  color: #444;
  text-decoration-style: wavy;
  text-decoration-line: underline;
  text-decoration-color: #fe5f58;
}
psw {
  color: transparent;
  background: #a1a1a1;
  border-radius: 2px;
  transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
}
psw:hover {
  color: #444;
  background: none;
}
kbd {
  display: inline-block;
  color: #666;
  font: bold 9pt arial;
  text-decoration: none;
  text-align: center;
  padding: 2px 5px;
  margin: 0 5px;
  background: #eff0f2;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-top: 1px solid #f5f5f5;
  -webkit-box-shadow: inset 0 0 20px #e8e8e8, 0 1px 0 #c3c3c3, 0 1px 0 #c9c9c9, 0 1px 2px #333;
  -moz-box-shadow: inset 0 0 20px #e8e8e8, 0 1px 0 #c3c3c3, 0 1px 0 #c9c9c9, 0 1px 2px #333;
  -webkit-box-shadow: inset 0 0 20px #e8e8e8, 0 1px 0 #c3c3c3, 0 1px 0 #c9c9c9, 0 1px 2px #333;
  box-shadow: inset 0 0 20px #e8e8e8, 0 1px 0 #c3c3c3, 0 1px 0 #c9c9c9, 0 1px 2px #333;
  text-shadow: 0 1px 0 #f5f5f5;
}
#article-container .tag.link {
  text-align: center;
}
#article-container a.link-card {
  margin: 0.25rem auto;
  background: #f6f6f6;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  min-width: 200px;
  max-width: 361px;
  color: #444;
  border-radius: 12px;
  text-decoration: none;
}
#article-container a.link-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
}
#article-container a.link-card div.left {
  width: 48px;
  height: 48px;
  margin: 12px;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}
#article-container a.link-card div.left i {
  font-size: 32px;
  line-height: 48px;
  margin-left: 4px;
}
#article-container a.link-card div.left img {
  display: block;
  position: absolute;
  border-radius: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#article-container a.link-card div.right {
  overflow: hidden;
  margin-right: 12px;
}
#article-container a.link-card p {
  margin: 0;
}
#article-container a.link-card p.text {
  font-weight: bold;
}
#article-container a.link-card p.url {
  flex-shrink: 0;
  color: rgba(68,68,68,0.65);
  font-size: 13px;
}
@media screen and (max-width: 425px) {
  #article-container a.link-card {
    max-width: 100%;
  }
}
@media screen and (max-width: 375px) {
  #article-container a.link-card {
    width: 100%;
  }
}
#article-container a.link-card div.left,
#article-container a.link-card div.right {
  pointer-events: none;
}
[data-theme="dark"] #article-container a.link-card {
  filter: brightness(0.7);
}
[data-theme="dark"] #article-container a.link-card img {
  filter: brightness(1);
}
audio,
video {
  border-radius: 4px;
  max-width: 100%;
}
video {
  z-index: 1;
  transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
}
video:hover {
  box-shadow: 0 4px 8px 0px rgba(0,0,0,0.24), 0 8px 16px 0px rgba(0,0,0,0.24);
}
div.video {
  line-height: 0;
  text-align: center;
}
div.videos {
  max-width: calc(100% + 2 * 4px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 1em -4px;
}
div.videos .video,
div.videos iframe {
  width: 100%;
  margin: 4px;
}
div.videos iframe {
  border-radius: 4px;
  width: 100%;
  min-height: 300px;
}
div.videos.left {
  justify-content: flex-start;
}
div.videos.center {
  justify-content: center;
}
div.videos.right {
  justify-content: flex-end;
}
div.videos.stretch {
  align-items: stretch;
}
div.videos[col='1'] .video,
div.videos[col='1'] iframe {
  width: 100%;
}
div.videos[col='2'] .video,
div.videos[col='2'] iframe {
  width: calc(50% - 2 * 4px);
}
div.videos[col='3'] .video,
div.videos[col='3'] iframe {
  width: calc(33.33% - 2 * 4px);
}
div.videos[col='4'] .video,
div.videos[col='4'] iframe {
  width: calc(25% - 2 * 4px);
}
[data-theme="dark"] audio,
[data-theme="dark"] video {
  filter: brightness(0.7);
}
span.nota {
  color: #99a9bf;
  text-decoration: none;
  word-wrap: break-word;
  transition: all 0.2s;
  overflow-wrap: break-word;
}
span.nota:hover {
  color: var(--text-bg-hover);
}
span.nota:hover:before {
  position: fixed;
  width: fit-content;
  max-width: 80%;
  margin: auto;
  left: 0;
  right: 0;
  top: 10%;
  border-radius: 10px;
  text-align: center;
  z-index: 100;
  content: attr(data-nota);
  font-size: 16px;
  color: #fff;
  padding: 10px;
  background-color: var(--text-bg-hover);
}
[data-theme=dark] span.nota:hover:before {
  background-color: rgba(18,18,18,0.8);
}
@media (min-width: 1200px) {
  .poem {
    margin: 0 auto;
    height: auto;
    writing-mode: vertical-rl;
    writing-mode: tb-rl;
  }
  .poem p {
    text-decoration: underline;
    text-decoration-color: rgba(193,11,11,0.72);
    text-decoration-style: dashed;
  }
}
@font-face {
  font-family: 'Poem';
  src: url("https://unpkg.zhimg.com/akilar-candyassets/fonts/Poem.ttf");
  font-display: swap;
}
.poem p {
  font-family: 'Poem', 'KaiTi', sans-serif !important;
  font-size: 25px;
  text-align: center;
}
.poem-title {
  font-family: 'Poem', 'KaiTi', sans-serif !important;
  font-size: 2.5em;
  text-align: center;
}
.poem-author {
  text-align: center !important;
  font-family: 'Poem', 'KaiTi', sans-serif !important;
  font-size: 16px;
  color: #424242;
}
.progress {
  display: flex;
  font-size: var(--global-font-size);
  background-color: rgba(88,88,88,0.6);
  border-radius: 0.25rem;
  margin: 1rem 0;
  height: 2rem;
  overflow: hidden;
}
.progress p {
  margin: 0 0 0 10px !important;
}
.progress .progress-bar-animated {
  background-color: #a7b5fd !important;
  animation: progress-bar-stripes 1s linear infinite;
}
.progress .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress .progress-bar {
    transition: none;
  }
}
.progress .bg-green {
  background-color: #28a745 !important;
}
.progress .bg-yellow {
  background-color: #ffc107 !important;
}
.progress .bg-red {
  background-color: #dc3545 !important;
}
.progress .bg-cyan {
  background-color: #17a2b8 !important;
}
.progress .bg-blue {
  background-color: #0d6efd !important;
}
.progress .bg-gray {
  background-color: #7f838a !important;
}
@-moz-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@-o-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
:root {
  --referto-text-color: #e9a218;
  --referto-text-hover-color: #2c7fe7;
  --referto-literature-background-color: #318efd;
  --referto-title-background-color: #183153;
  --referto-content-text-color: #f1f1ef;
  --referfrom-text-shadow: rgba(35,35,35,0.5);
  --referfrom-text-color: #f1f1ef;
  --referfrom-text-hover-color: #f2b94b;
  --referfrom-link-color: #403e3b;
  --referfrom-link-hover-color: #235dac;
  --referfrom-background-color: #f1f1ef;
  --reference-anchor-color: #183153;
  --reference-anchor-up-color: #318efd;
}
[data-theme="dark"] {
  --referto-text-color: #f2b94b;
  --referto-text-hover-color: #2c7fe7;
  --referto-literature-background-color: #5c6167;
  --referto-title-background-color: #090a0b;
  --referto-content-text-color: #f1f1ef;
  --referfrom-text-shadow: rgba(35,35,35,0.5);
  --referfrom-text-color: #f1f1ef;
  --referfrom-text-hover-color: #f2b94b;
  --referfrom-link-color: #f1f1ef;
  --referfrom-link-hover-color: #f2b94b;
  --referfrom-background-color: rgba(92,97,103,0.68);
  --reference-anchor-color: #090a0b;
  --reference-anchor-up-color: #183153;
}
.hidden-anchor {
  position: relative;
  top: -150px;
  display: inline-block;
  height: 0;
  overflow: hidden;
}
.reference a {
  display: inline-block;
  color: var(--referto-text-color) !important;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}
.reference:hover a {
  transition: all 0.2s ease-in-out;
  text-decoration: none !important;
  color: var(--referto-text-hover-color) !important;
}
.reference:hover + .reference-bubble .reference-item {
  transform: translate(-40px, 10px) rotateX(0deg);
  transition: all 0.5s ease-in-out;
  opacity: 1;
}
.reference-bubble {
  display: inline-block;
}
.reference-item {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: 99;
  display: flex;
  position: absolute;
  transform: translate(-40px, 10px) rotateX(90deg);
  width: auto;
  height: auto;
  clip-path: polygon(5px 10px, 20px 10px, 30px 0, 40px 10px, calc(100% - 5px) 10px, 100% 15px, 100% calc(100% - 5px), calc(100% - 5px) 100%, 5px 100%, 0 calc(100% - 5px), 0 15px, 5px 10px);
}
.reference-literature {
  max-width: 200px;
  padding: 20px 10px 10px 10px;
  background: var(--referto-literature-background-color);
  color: var(--referto-content-text-color) !important;
  word-wrap: break-word;
  word-break: break-all;
  display: flex;
  align-items: center;
}
.reference-title {
  padding: 20px 10px 10px 10px;
  background: var(--referto-title-background-color);
  color: var(--referto-content-text-color) !important;
  writing-mode: vertical-lr;
  display: flex;
  justify-content: center;
}
.reference-source {
  font-size: 16px;
  height: auto;
  line-height: 26px;
  width: fit-content;
  margin: 5px 0;
  max-width: 90%;
  background: var(--referfrom-background-color);
  box-shadow: 1px 1px 1px var(--referfrom-text-shadow);
  border-radius: 8px;
  padding-right: 10px;
}
.reference-source pangu {
  display: none;
}
a.reference-anchor {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  color: var(--referfrom-text-color) !important;
  background: var(--reference-anchor-color);
  height: 30px;
  display: inline-block;
  width: fit-content;
  padding: 0px 0px 0px 10px;
}
a.reference-anchor:hover {
  color: var(--referfrom-text-hover-color) !important;
  text-decoration: none !important;
}
a.reference-anchor:hover .reference-anchor-up {
  color: var(--referfrom-text-hover-color) !important;
}
a.reference-link {
  color: var(--referfrom-link-color) !important;
  padding: 0px 10px;
}
a.reference-link:hover {
  color: var(--referfrom-link-hover-color) !important;
  text-decoration: none !important;
}
.reference-anchor-up {
  color: var(--referfrom-text-color) !important;
  height: 30px;
  width: 40px;
  padding: 0px 0px 0px 10px;
  background: var(--reference-anchor-up-color);
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 10px 50%, 0 100%, 100% 100%, 100% 0);
}
.site-card-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -8px;
  align-items: stretch;
}
.site-card {
  margin: 8px;
  width: calc(100% / 4 - 16px);
  display: block;
  line-height: 1.4;
  height: 100%;
}
@media screen and (min-width: 2048px) {
  .site-card {
    width: calc(100% / 5 - 16px);
  }
}
@media screen and (max-width: 768px) {
  .site-card {
    width: calc(100% / 3 - 16px);
  }
}
@media screen and (max-width: 500px) {
  .site-card {
    width: calc(100% / 2 - 16px);
  }
}
.site-card .img {
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.2);
  background: #f6f6f6;
}
@media screen and (max-width: 500px) {
  .site-card .img {
    height: 100px;
  }
}
.site-card .img img {
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: transform 2s ease;
  object-fit: cover;
}
.site-card .info {
  margin-top: 8px;
}
.site-card .info img {
  width: 32px;
  height: 32px;
  pointer-events: none;
  border-radius: 16px;
  float: left;
  margin-right: 8px;
  margin-top: 2px;
}
.site-card .info span {
  display: block;
}
.site-card .info .title {
  font-weight: 600;
  font-size: var(--global-font-size);
  color: #444;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
}
.site-card .info .desc {
  font-size: var(--global-font-size);
  word-wrap: break-word;
  line-height: 1.2;
  color: #888;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
.site-card .img {
  transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
}
.site-card:hover .img {
  box-shadow: 0 4px 8px 0px rgba(0,0,0,0.1), 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
}
.site-card:hover .info .title {
  color: #ff5722;
}
p.p.subtitle {
  font-weight: bold;
  color: #44b299;
  font-size: 1.25rem !important;
  padding-top: 1.5rem;
}
p.p.subtitle:first-child {
  padding-top: 1rem;
}
span.p.left,
p.p.left {
  display: block;
  text-align: left;
}
span.p.center,
p.p.center {
  display: block;
  text-align: center;
}
span.p.right,
p.p.right {
  display: block;
  text-align: right;
}
span.p.small,
p.p.small {
  font-size: var(--global-font-size);
}
span.p.large,
p.p.large {
  font-size: 2.5rem;
  line-height: 1.4;
}
span.p.huge,
p.p.huge {
  font-size: 4rem;
  line-height: 1.4;
}
span.p.ultra,
p.p.ultra {
  font-size: 6rem;
  line-height: 1.4;
}
span.p.small,
p.p.small,
span.p.large,
p.p.large,
span.p.huge,
p.p.huge,
span.p.ultra,
p.p.ultra {
  margin: 0;
  padding: 0;
}
span.p.bold,
p.p.bold {
  font-weight: bold;
}
span.p.h1,
p.p.h1,
span.p.h2,
p.p.h2 {
  padding-bottom: 0.2rem;
  font-weight: 500;
}
span.p.h1,
p.p.h1 {
  font-size: 1.625rem;
  color: var(--color-h1);
  padding-top: 2em;
}
span.p.h2,
p.p.h2 {
  font-size: 1.625rem;
  color: var(--color-h2);
  padding-top: 2em;
  border-bottom: 1px solid rgba(68,68,68,0.1);
}
span.p.h3,
p.p.h3 {
  font-size: 1.375rem;
  color: var(--color-h3);
  padding-top: 2em;
}
span.p.h4,
p.p.h4 {
  font-size: 1.125rem;
  color: var(--color-h4);
  padding-top: 2em;
}
span.p.h5,
p.p.h5 {
  font-size: 1rem;
  color: var(--color-h5);
  padding-top: 1.5em;
}
span.p.red,
p.p.red {
  color: #e8453c;
}
span.p.yellow,
p.p.yellow {
  color: #fcec60;
}
span.p.green,
p.p.green {
  color: #3dc550;
}
span.p.cyan,
p.p.cyan {
  color: #1bcdfc;
}
span.p.blue,
p.p.blue {
  color: #2196f3;
}
span.p.purple,
p.p.purple {
  color: #9c27b0;
}
span.p.gray,
p.p.gray {
  color: #999;
}
.tip {
  padding: 6px 20px;
  position: relative;
  color: #fff;
  margin-bottom: 20px;
  background: #41363c;
  background: -webkit-gradient(linear, left top, right top, from(#41363c), to(#494648));
  background: -webkit-linear-gradient(left, #41363c, #494648);
  background: linear-gradient(90deg, #41363c, #494648);
  padding: 6px 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 5px rgba(50,50,50,0.5);
  box-shadow: 0 3px 5px rgba(50,50,50,0.5);
}
.tip p {
  margin: 0 !important;
  padding: 0.5rem 0;
}
.tip:before {
  background: #41363c;
  background: -webkit-gradient(linear, left bottom, left top, from(#41363c), to(#494648));
  background: -webkit-linear-gradient(bottom, #41363c, #494648);
  background: linear-gradient(0deg, #41363c, #494648);
  text-shadow: 0 -1px #41363c;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  position: absolute;
  width: 24px;
  height: 24px;
  line-height: 24.5px;
  left: -12px;
  top: -12px;
  -webkit-box-shadow: 0 0 0 2.5px #f7f8f9;
  box-shadow: 0 0 0 2.5px #f7f8f9;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  text-align: center;
}
.tip ol {
  margin: 0;
}
.tip.info {
  background: #20a0ff;
  background: -webkit-gradient(linear, left top, right top, from(#20a0ff), to(#20b8ff));
  background: -webkit-linear-gradient(left, #20a0ff, #20b8ff);
  background: linear-gradient(90deg, #20a0ff, #20b8ff);
  padding: 6px 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 5px rgba(32,160,255,0.5);
  box-shadow: 0 3px 5px rgba(32,160,255,0.5);
}
.tip.info:before {
  background: #20a0ff;
  background: -webkit-gradient(linear, left bottom, left top, from(#0092ff), to(#20b8ff));
  background: -webkit-linear-gradient(bottom, #0092ff, #20b8ff);
  background: linear-gradient(0deg, #0092ff, #20b8ff);
  content: "\f129";
  text-shadow: 0 -1px #20a0ff;
}
.tip.success {
  background: #61be33;
  background: -webkit-gradient(linear, left top, right top, from(#61be33), to(#8fce44));
  background: -webkit-linear-gradient(left, #61be33, #8fce44);
  background: linear-gradient(90deg, #61be33, #8fce44);
  text-shadow: 0 -1px #61be33;
  -webkit-box-shadow: 0 3px 5px rgba(104,195,59,0.5);
  box-shadow: 0 3px 5px rgba(104,195,59,0.5);
}
.tip.success:before {
  background: -webkit-gradient(linear, left bottom, left top, from(#52bb1d), to(#95d34b));
  background: -webkit-linear-gradient(bottom, #52bb1d, #95d34b);
  background: linear-gradient(0deg, #52bb1d, #95d34b);
  content: "\f00c";
  text-shadow: 0 -1px #61be33;
}
.tip.warning {
  background: #ff953f;
  background: -webkit-gradient(linear, left top, right top, from(#ff953f), to(#ffb449));
  background: -webkit-linear-gradient(left, #ff953f, #ffb449);
  background: linear-gradient(90deg, #ff953f, #ffb449);
  text-shadow: 0 -1px #ff953f;
  -webkit-box-shadow: 0 3px 5px rgba(255,154,73,0.5);
  box-shadow: 0 3px 5px rgba(255,154,73,0.5);
}
.tip.warning:before {
  background: -webkit-gradient(linear, left bottom, left top, from(#ff8f35), to(#ffc149));
  background: -webkit-linear-gradient(bottom, #ff8f35, #ffc149);
  background: linear-gradient(0deg, #ff8f35, #ffc149);
  content: "\f12a";
  text-shadow: 0 -1px #ff953f;
}
.tip.error {
  background: #ff4949;
  background: -webkit-gradient(linear, left top, right top, from(#ff4949), to(#ff7849));
  background: -webkit-linear-gradient(left, #ff4949, #ff7849);
  background: linear-gradient(90deg, #ff4949, #ff7849);
  text-shadow: 0 -1px #ff4949;
  -webkit-box-shadow: 0 3px 5px rgba(255,73,73,0.5);
  box-shadow: 0 3px 5px rgba(255,73,73,0.5);
}
.tip.error:before {
  background: -webkit-gradient(linear, left bottom, left top, from(#ff3838), to(#ff7849));
  background: -webkit-linear-gradient(bottom, #ff3838, #ff7849);
  background: linear-gradient(0deg, #ff3838, #ff7849);
  content: "\f00d";
  text-shadow: 0 -1px #ff4949;
}
.tip.bolt {
  background: -webkit-gradient(linear, left bottom, left top, from(#3d8b48), to(#477837));
  background: -webkit-linear-gradient(bottom, #3c3, #459431);
  background: linear-gradient(530deg, #78ca33, #25822c);
  content: "\f00d";
  text-shadow: 0 -1px #4cf706;
}
.tip.bolt:before {
  background: -webkit-gradient(linear, left bottom, left top, from(#3c0), to(#3c0));
  background: -webkit-linear-gradient(bottom, #3c3, #459431);
  background: linear-gradient(776deg, #78ca33, #25822c);
  content: "\f0e7";
  text-shadow: 0 -1px #4cf706;
}
.tip.ban {
  background: #ff4949;
  background: -webkit-gradient(linear, left top, right top, from(#ff4949), to(#ff3443));
  background: -webkit-linear-gradient(left, #ff4949, #ff1022);
  background: linear-gradient(90deg, #ff4949, #f03b49);
  text-shadow: 0 -1px #ff4949;
  -webkit-box-shadow: 0 3px 5px rgba(255,73,73,0.5);
  box-shadow: 0 3px 5px rgba(255,73,73,0.5);
}
.tip.ban:before {
  background: -webkit-gradient(linear, left bottom, left top, from(#ff3838), to(#ce4617));
  background: -webkit-linear-gradient(bottom, #ff3838, #d23e49);
  background: linear-gradient(0deg, #ff3838, #ff1022);
  content: "\f05e";
  text-shadow: 0 -1px #ff4949;
}
.tip.home {
  background: #15e5ff;
  background: -webkit-gradient(linear, left top, right top, from(#5bc6d4) to(#0ec0ef));
  background: -webkit-linear-gradient(left, #0ec0ef, #80e0f9);
  background: linear-gradient(90deg, #0ec0ef, #80e0f7);
  text-shadow: 0 -1px #0ec0ef;
  -webkit-box-shadow: 0 3px 5px #01caff;
  box-shadow: 0 3px 5px #01caff;
}
.tip.home:before {
  background: -webkit-gradient(linear, left bottom, left top, form(#0ec0ee) to(#0ee0cc));
  background: -webkit-linear-gradient(bottom, #0ec0ee, #0ec2ee);
  background: linear-gradient(0deg, #0ec0ee, #0ec0ea);
  content: "\f015";
  text-shadow: 0 -1px #0ec0ea;
}
.tip.sync {
  background: #00a9ff;
  background: -webkit-gradient(linear, left top, right top, from(rgba(81,167,189,0.2)), to(#c7eef9));
  background: -webkit-linear-gradient(left, #53cff1, #2e9fbd);
  background: linear-gradient(230deg, #47c0e0, #2dc342);
  text-shadow: 0 -1px #1bcdfc;
  -webkit-box-shadow: 0 3px 5px #1bcdfc;
  box-shadow: 0 3px 5px #20b1ad;
}
.tip.sync:before {
  background: -webkit-gradient(linear, left bottom, left top, from(#00c3f7), to(#88d3e6));
  background: -webkit-linear-gradient(bottom, #83e5ff, #0aa8d2);
  background: linear-gradient(270deg, #40c0e2, #3dc550);
  content: "\f021";
  text-shadow: 0 -1px #17cfff;
}
.tip.cogs {
  background: #1502ff;
  background: -webkit-gradient(linear, left top, right top, from(rgba(81,167,189,0.2)), to(#8379ff));
  background: -webkit-linear-gradient(left, #5246e2, #5246e2);
  background: linear-gradient(230deg, #40c0e2, #5247e2);
  text-shadow: 0 -1px #8278fd;
  -webkit-box-shadow: 0 3px 5px #4037a7;
  box-shadow: 1 3px 5px #5e52ec;
}
.tip.cogs:before {
  background: -webkit-gradient(linear, left bottom, left top, from(#3020f3), to(#b1abf5));
  background: -webkit-linear-gradient(bottom, #5246e2, #5246e2);
  background: linear-gradient(560deg, #40c0e2, #5246e2);
  content: "\f085";
  text-shadow: 0 -1px #098cf5;
}
.tip.key {
  background: #25c33b;
  background: -webkit-gradient(linear, left top, right top, from(rgba(81,167,189,0.2)), to(#8379ff));
  background: -webkit-linear-gradient(left, #648798, #90a4ae);
  background: linear-gradient(230deg, #90a4ae, #b7a7a7);
  text-shadow: 0 -1px #c1c0d4;
  -webkit-box-shadow: 0 3px 5px #d3d2de;
  box-shadow: 1 3px 5px #d5d4de;
}
.tip.key:before {
  background: -webkit-gradient(linear, left bottom, left top, from(#dddce8), to(#b1abf5));
  background: -webkit-linear-gradient(bottom, #5246e2, #5246e2);
  background: linear-gradient(560deg, #bccdd2, #cfced4);
  content: "\f084";
  text-shadow: 0 -1px #a9b2b9;
}
.tip.bell {
  background: #25c33b;
  background: -webkit-gradient(linear, left top, right top, from(rgba(81,167,189,0.2)), to(#8379ff));
  background: -webkit-linear-gradient(left, #648798, #90a4ae);
  background: linear-gradient(230deg, #ffaa0d, #deb455);
  text-shadow: 0 -1px #c1c0d4;
  -webkit-box-shadow: 0 3px 5px #d3d2de;
  box-shadow: 1 3px 5px #d5d4de;
}
.tip.bell:before {
  background: -webkit-gradient(linear, left bottom, left top, from(#dddce8), to(#b1abf5));
  background: -webkit-linear-gradient(bottom, #5246e2, #5246e2);
  background: linear-gradient(560deg, #f9ae07, #ffb615);
  content: "\f0f3";
  text-shadow: 0 -1px #ffb81b;
}
[data-theme="dark"] .tip {
  filter: brightness(0.7);
}
#article-container .tip a {
  color: #e6eaed;
}
